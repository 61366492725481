import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { ICustomer } from "../interfaces/customer.interface";
import { IResponseDTO } from "../interfaces/response.interface";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public searchCustomers(params: any) {
    return this.http.post<IResponseDTO<ICustomer[]>>(
      `${this.apiUrl}/customer-accounts/search`,
      {
        ...params,
        withCredentials: true,
      }
    );
  }

  public getCustomers(
    property: string = "",
    limit: number = 10,
    page: number = 1,
    sort: string = "name",
    order: string = "asc"
  ): Observable<IResponseDTO<ICustomer[]>> {
    const body = {
      limit,
      page,
      sort,
      order,
      property,
    };
    return this.http.post<IResponseDTO<ICustomer[]>>(
      `${this.apiUrl}/customer-accounts/search`,
      body,
      {
        withCredentials: true,
        headers: {
          property,
        },
      }
    );
  }

  public getAllCustomers(
    property: string = ""
  ): Observable<IResponseDTO<ICustomer[]>> {
    return this.http.get<IResponseDTO<ICustomer[]>>(
      `${this.apiUrl}/customer-accounts/all`,
      {
        withCredentials: true,
        headers: {
          property,
        },
      }
    );
  }

  public getEntities(property: string = "") {
    return this.http.get<IResponseDTO<ICustomer[]>>(`${this.apiUrl}/entities`, {
      withCredentials: true,
      headers: {
        property,
      },
    });
  }

  public getCustomer(id: string = "") {
    return this.http.get<ICustomer>(`${this.apiUrl}/customer-accounts/${id}`, {
      withCredentials: true,
    });
  }

  public deleteCustomer(id: string) {
    return this.http.delete(`${this.apiUrl}/customer-accounts/${id}`, {
      withCredentials: true,
    });
  }
}
